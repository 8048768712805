import { HeaderBar } from "../components/HeaderBar"
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import React, { DragEvent, useEffect, useState } from "react"
import { Footer } from "../components/Footer"
import { useDirectAPI } from "../services/api/hooks/useDirectAPI"
import { DirectQueryType, DirectType } from "../services/api/direct"
import { useAuthContext } from "../services/auth"
import { UserRole } from "../services/auth/auth.types"
import { useTagAPI } from "../services/api/hooks/useTagAPI"
import { useSnackbarContext } from "../services/snackbar"
import { httpClient } from "../services/http"
import { VideoQueryType, VideoType } from "../services/api/video"
import { VideoPlayer } from "../components/VideoPlayer"
import { useHistory, useParams } from "react-router-dom"
import { useVideoAPI } from "../services/api/hooks/useVideoAPI"
import AspectRatioBox from "../components/AspectRatioBox"
import { VideoThumbnailImage } from "../components/VideoThumbnail"
import { uploadThumbnail, uploadVideo } from "../utils/upload"
import { ClickAwayListener, Unstable_Popup as Popup } from "@mui/base"
import { Main, PageTitle } from "../components/StyledElements"
import { StyledTextField } from "../components/Util"

const FieldContainer = styled(Grid)(({ theme }) => ({
  padding: "20px",
}))

export const EditVideo = (): React.ReactElement => {
  const { videoId } = useParams<{ videoId: string }>()
  const [videoFile, setVideoFile] = useState<File | null>(null)
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null)
  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [date, setDate] = useState<string>(new Date().toISOString().split("T")[0])
  const [promotion, setPromotion] = useState<number | null>(null)
  const [directSearch, setDirectSearch] = useState<string>("")
  const [direct, setDirect] = useState<DirectType | null>(null)
  const [originalVideo, setOriginalVideo] = useState<VideoType | null>(null)
  const [privacy, setPrivacy] = useState<number>(1)
  const [tagIds, setTagIds] = useState<number[]>([])

  const [isUploading, setIsUploading] = useState<boolean>(false)

  const [isDraggingVideo, setIsDraggingVideo] = useState(false)
  const [isDraggingThumbnail, setIsDraggingThumbnail] = useState(false)

  const [isTrendingPopupOpen, setTrendingPopupOpen] = useState(false)

  const { role } = useAuthContext()
  const { open: sbOpen } = useSnackbarContext()
  const theme = useTheme()

  const { directsData, loading: directsLoading } = useDirectAPI({
    constraint: DirectQueryType.SEARCH,
    search: directSearch,
  })
  const {
    directsData: originalDirectData,
    loading: originalDirectLoading,
    refresh: originalDirectRefresh,
  } = useDirectAPI({
    constraint: DirectQueryType.DIRECT,
    id: (originalVideo?.directId || -1).toString(),
  })
  const {
    videosData,
    loading: videoLoading,
    error: videoError,
  } = useVideoAPI({ constraint: VideoQueryType.VIDEO, id: parseInt(videoId) })
  const { tagsData, loading: tagsLoading } = useTagAPI({})
  const history = useHistory()

  const handleVideoFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file?.type === "video/mp4") {
      setVideoFile(file || null)
    }
  }

  const handleDragOverVideo = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingVideo(true)
  }

  const handleDragLeaveVideo = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingVideo(false)
  }

  const handleDropVideo = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingVideo(false)
    const files = event.dataTransfer.files
    if (files.length > 0 && files[0].type === "video/mp4") {
      setVideoFile(files[0])
    }
  }

  const handleThumbnailFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setThumbnailFile(file || null)
    }
  }

  const handleDragOverThumbnail = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingThumbnail(true)
  }

  const handleDragLeaveThumbnail = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingThumbnail(false)
  }

  const handleDropThumbnail = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingThumbnail(false)
    const files = event.dataTransfer.files
    if (files.length > 0 && (files[0].type === "image/png" || files[0].type === "image/jpeg")) {
      setThumbnailFile(files[0])
    }
  }

  const handleDirectFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirect(null)
    setDirectSearch(e.target.value)
  }

  const handlePrivacyChange = (e: SelectChangeEvent<number>) => {
    setPrivacy(e.target.value as number)
  }

  const addTag = (tag: number) => {
    setTagIds([...tagIds, tag])
  }

  const removeTag = (id: number) => {
    setTagIds(tagIds.filter((tag) => tag != id))
  }

  const handleSubmit = async () => {
    setIsUploading(true)
    const data = {
      title: title,
      description: description,
      privacy: privacy,
      date: date.toString(),
      promotion: (promotion || -1).toString(),
      direct_id: (direct?.id || -1).toString(),
      tagIds: tagIds,
    }

    let videoResponse: VideoType | null = null

    try {
      videoResponse = await httpClient.instance.put(`videos/${videoId}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    } catch (error) {
      sbOpen("Erreur pendant la modification de la vidéo", "error")
    } finally {
      if (!videoResponse) {
        sbOpen("Erreur pendant la modification de la vidéo", "error")
      }
      try {
        videoResponse && videoFile && (await uploadVideo(videoResponse, videoFile, sbOpen))
        videoResponse && thumbnailFile && (await uploadThumbnail(videoResponse, thumbnailFile, sbOpen))
        sbOpen("Vidéo modifiée avec succès", "success")
      } catch {
        sbOpen("Erreur pendant un upload...", "error")
      } finally {
        setIsUploading(false)
        history.push(originalVideo?.processing === 3 ? `/video/${videoId}` : "/pending")
      }
    }
  }

  const handleSetTrending = async (score: number) => {
    let response
    try {
      response = await httpClient.instance.put(`videos/trending/${score}/${videoId}`)
    } catch (error) {
      sbOpen("Erreur pendant l'opération", "error")
    } finally {
      if (!response) {
        sbOpen("Erreur pendant l'opération", "error")
      }
      sbOpen("Vidéo mise à la une avec succès", "success")
    }
  }

  useEffect(() => {
    if (videoLoading) return
    if (videoError) {
      sbOpen("Erreur pendant le chargement de la vidéo", "error")
      history.push("/")
      return
    }

    const video = videosData[0]

    setOriginalVideo(video)
    setTitle(video.title)
    setDescription(video.description)
    setDate(video.date)
    setPromotion(video.promotion === -1 ? null : video.promotion || null)
    setPrivacy(video.privacy)
    setTagIds(video.tagIds)
    originalDirectRefresh()
  }, [videoLoading, videoError])

  useEffect(() => {
    originalDirectLoading || setDirect(originalDirectData[0] || null)
  }, [originalDirectData])

  return (
    <React.Fragment>
      <HeaderBar />
      <Main>
        <PageTitle>{"Modifier une vidéo"}</PageTitle>
        {videoLoading && originalVideo ? (
          <Typography color="textPrimary">{"Chargement..."}</Typography>
        ) : (
          <Container>
            <Grid container direction="row">
              <Grid container item md={12} pt={3}>
                <Grid item xs={12} md={originalVideo?.processing === 3 ? 12 : 6}>
                  <AspectRatioBox ratio={16 / 9}>
                    {originalVideo?.processing ? (
                      <VideoPlayer
                        id={originalVideo.id}
                        raw={originalVideo.processing < 3}
                        autoplay={false}
                        disableKeyboardShortcuts
                      />
                    ) : (
                      <Typography
                        textAlign="center"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        color="error"
                      >
                        {"Aucune vidéo uploadée"}
                      </Typography>
                    )}
                  </AspectRatioBox>
                </Grid>
                {originalVideo?.processing === 3 || (
                  <FieldContainer item xs={12} md={6}>
                    <FormControl fullWidth focused>
                      <Input
                        id="video-file-input"
                        type="file"
                        onChange={handleVideoFileInputChange}
                        sx={{ display: "none" }}
                        inputProps={{ style: { display: "none" } }}
                      />
                      <Box
                        sx={{
                          border: `2px solid ${theme.palette.soft.main}`,
                          borderRadius: "4px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          py: 5,
                          cursor: "pointer",
                          backgroundColor: isDraggingVideo ? "primary.dark" : "inherit",
                        }}
                        onClick={() => document.getElementById("video-file-input")?.click()}
                        onDragOver={handleDragOverVideo}
                        onDragLeave={handleDragLeaveVideo}
                        onDrop={handleDropVideo}
                      >
                        {videoFile ? videoFile.name : "Pour modifier la vidéo, sélectionnez un fichier .mp4 ici"}
                      </Box>
                    </FormControl>
                  </FieldContainer>
                )}
              </Grid>
              <Grid container item md={12} pt={3}>
                <Grid item xs={12} md={6}>
                  <AspectRatioBox ratio={16 / 9}>
                    <VideoThumbnailImage video={originalVideo} />
                  </AspectRatioBox>
                </Grid>
                <FieldContainer item xs={12} md={6}>
                  <FormControl fullWidth focused>
                    <Input
                      id="thumbnail-input"
                      type="file"
                      onChange={handleThumbnailFileInputChange}
                      sx={{ display: "none" }}
                      inputProps={{ style: { display: "none" } }}
                    />
                    <Box
                      sx={{
                        border: `2px solid ${theme.palette.soft.main}`,
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        py: 5,
                        cursor: "pointer",
                        backgroundColor: isDraggingThumbnail ? "#111111" : "inherit",
                      }}
                      onClick={() => document.getElementById("thumbnail-input")?.click()}
                      onDragOver={handleDragOverThumbnail}
                      onDragLeave={handleDragLeaveThumbnail}
                      onDrop={handleDropThumbnail}
                    >
                      {thumbnailFile
                        ? thumbnailFile.name
                        : "Pour modifier la miniature, sélectionnez un fichier .png ou .jpg ici"}
                    </Box>
                  </FormControl>
                </FieldContainer>
              </Grid>
              <Grid item xs={12} md={6} container direction="row">
                <FieldContainer item xs={12}>
                  <StyledTextField
                    label="Titre"
                    variant="standard"
                    color="soft"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    autoFocus
                    focused
                  />
                </FieldContainer>
                <FieldContainer item xs={12}>
                  <StyledTextField
                    label="Description"
                    variant="outlined"
                    color="soft"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    minRows={8}
                    maxRows={12}
                    focused
                  />
                </FieldContainer>
              </Grid>
              <Grid item xs={12} md={6} container direction="row">
                <FieldContainer item xs={6}>
                  <StyledTextField
                    label="Date"
                    variant="standard"
                    color="soft"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    focused
                  />
                </FieldContainer>
                <FieldContainer item xs={6}>
                  <FormControl fullWidth variant="standard" focused>
                    <StyledTextField
                      label="Promo"
                      type="text"
                      variant="standard"
                      color="soft"
                      value={promotion || ""}
                      onChange={(e) => setPromotion(e.target.value === "" ? null : parseInt(e.target.value))}
                      placeholder=""
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{"P"}</InputAdornment>,
                      }}
                    />
                  </FormControl>
                </FieldContainer>
                <FieldContainer item xs={direct ? 10 : 12}>
                  <StyledTextField
                    label="Direct associé"
                    variant="standard"
                    color="soft"
                    value={direct ? direct.title : directSearch}
                    onChange={handleDirectFieldChange}
                    focused
                  />
                  {!directsLoading && !direct && directsData.length > 0 && (
                    <>
                      {directsData.slice(0, 3).map((direct) => (
                        <Box
                          key={direct.id}
                          sx={{
                            backgroundColor: "primary.main",
                            "&:hover": { backgroundColor: "primary.light" },
                            transition: ".2s",
                            cursor: "pointer",
                          }}
                          onClick={() => setDirect(direct)}
                        >
                          <Typography sx={{ padding: 1, pl: 2 }} textAlign="start">
                            {direct.title}
                          </Typography>
                        </Box>
                      ))}
                    </>
                  )}
                </FieldContainer>
                {direct && (
                  <FieldContainer item xs={2}>
                    <Typography color="darkgrey" align="center">
                      {direct.id}
                    </Typography>
                  </FieldContainer>
                )}
                <FieldContainer item xs={4}>
                  <Select
                    value={privacy}
                    variant="standard"
                    color="soft"
                    onChange={handlePrivacyChange}
                    sx={{ minWidth: 120 }}
                    disabled={role !== UserRole.GEEK && privacy === 0}
                  >
                    {(role === UserRole.GEEK || privacy === 0) && <MenuItem value={0}>{"Publique"}</MenuItem>}
                    <MenuItem value={1}>{"CS"}</MenuItem>
                    <MenuItem value={2}>{"Hyris"}</MenuItem>
                    {role === UserRole.GEEK && <MenuItem value={3}>{"Archivée"}</MenuItem>}
                  </Select>
                  <FormHelperText>{"Accès"}</FormHelperText>
                </FieldContainer>
                {!tagsLoading && tagsData.length != 0 && (
                  <FieldContainer item xs={8}>
                    <Typography pb={2} color={theme.palette.soft.main}>
                      {"Catégories"}
                    </Typography>
                    {tagsData.map((tag) => (
                      <Chip
                        key={tag.id}
                        color={tagIds.some((t) => t === tag.id) ? "secondary" : "primary"}
                        label={tag.name}
                        onClick={tagIds.some((t) => t === tag.id) ? () => removeTag(tag.id) : () => addTag(tag.id)}
                        sx={{ margin: 1 }}
                      />
                    ))}
                  </FieldContainer>
                )}
                <FieldContainer item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                    disabled={title === ""}
                    sx={{ margin: 1 }}
                  >
                    {"Valider"}
                  </Button>
                  {role === UserRole.GEEK && originalVideo?.processing === 3 && (
                    <>
                      <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ margin: 1 }}
                        onClick={() => setTrendingPopupOpen(true)}
                        id="trending-button"
                      >
                        {"Mettre à la une"}
                      </Button>
                      <Popup
                        anchor={document.querySelector("#trending-button")}
                        open={isTrendingPopupOpen}
                        placement="top"
                      >
                        <ClickAwayListener onClickAway={() => setTrendingPopupOpen(false)}>
                          <Box padding={2} borderRadius="5px" sx={{ backgroundColor: "primary.main" }}>
                            {[1, 2, 3, 4, 5].map((score) => (
                              <Chip
                                key={score}
                                label={score}
                                color="secondary"
                                onClick={() => {
                                  setTrendingPopupOpen(false)
                                  handleSetTrending(score)
                                }}
                                sx={{ margin: 1 }}
                              ></Chip>
                            ))}
                          </Box>
                        </ClickAwayListener>
                      </Popup>
                    </>
                  )}
                </FieldContainer>
              </Grid>
            </Grid>
          </Container>
        )}
      </Main>
      <Footer />

      <Backdrop sx={{ color: "#fff", zIndex: 300, display: "flex", flexDirection: "column" }} open={isUploading}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ mt: 2 }}>
          {"Upload en cours, veuillez ne pas quitter cette page..."}
        </Typography>
      </Backdrop>
    </React.Fragment>
  )
}
