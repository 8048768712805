import { alpha, Box, Button, ButtonProps, Grid, MenuItem, Select, styled, Typography, useTheme } from "@mui/material"
import React, { useEffect } from "react"
import { VideoType } from "services/api/video"
import { ReactComponent as LikeIcon } from "../assets/svg/like.svg"
import { httpClient } from "../services/http"
import { useTagAPI } from "../services/api/hooks/useTagAPI"
import { TagChip } from "./StyledElements"
import { useAuthContext } from "../services/auth"
import { useSnackbarContext } from "../services/snackbar"
import { FormatDate, FormatParagraphs } from "./Util"

const availableResolutions = [1080, 720, 480, 240]

interface LikeButtonProps extends ButtonProps {
  liked: boolean
}

class LikeResponse {
  liked = false
}

const LikeButton = styled(({ liked, ...props }: LikeButtonProps) => <Button {...props} />)(({ theme, liked }) => ({
  backgroundColor: liked ? theme.palette.like.main : "transparent",
  color: liked ? "white" : theme.palette.soft.main,
  border: `1px solid ${theme.palette.like.main}`,
  transition: "all 0.3s ease",
  display: "flex",
  alignItems: "center",
  gap: "6px",
  padding: "6px 12px",
  minHeight: "36px",
  "&:hover": {
    backgroundColor: liked ? theme.palette.like.dark : alpha(theme.palette.like.dark, 0.1),
    borderColor: theme.palette.like.dark,
  },
}))

interface VideoControlsProps {
  video: VideoType
  resolution?: number
  setResolution: React.Dispatch<React.SetStateAction<number>>
}

export const VideoControls = ({ video, resolution, setResolution }: VideoControlsProps): React.ReactElement => {
  const [liked, setLiked] = React.useState<boolean>(false)
  const { isAuth } = useAuthContext()
  const { open: sbOpen } = useSnackbarContext()
  const theme = useTheme()

  const { tagsData } = useTagAPI({})

  useEffect(() => {
    const fetchLike = async () => {
      try {
        const like: LikeResponse = await httpClient.instance.get(`/videos/like/${video.id}`)
        setLiked(like.liked)
      } catch (e) {
        console.error(e)
      }
    }
    fetchLike()
  }, [])

  const handleLike = async () => {
    setLiked(!liked)
    try {
      if (liked) {
        httpClient.instance.delete(`/videos/like/${video.id}`)
        video.likeCount && video.likeCount--
      } else {
        httpClient.instance.put(`/videos/like/${video.id}`)
        video.likeCount ? video.likeCount++ : (video.likeCount = 1)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleDownload = async () => {
    try {
      sbOpen("Téléchargement en arrière plan, veuillez ne pas quitter cette page...", "info")
      const response = await httpClient.instance.get<Blob>(`/media/video/download/${video.id}/1080`, {
        responseType: "blob",
      })

      const blobUrl = URL.createObjectURL(response)
      const a = document.createElement("a")
      a.href = blobUrl
      a.download = `${video.title}.mp4`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(blobUrl)
    } catch (error) {
      sbOpen("Téléchargement échoué", "error")
      console.error("Download failed:", error)
    }
  }

  return (
    video && (
      <Grid container direction="column" sx={{ backgroundColor: "primary.dark", padding: 2, borderRadius: "5px" }}>
        <Grid
          container
          item
          direction="row"
          md={12}
          borderBottom={`1px solid ${theme.palette.soft.main}`}
          sx={{ marginBottom: 2 }}
        >
          <Grid item md={7} sm={12}>
            <Typography variant="h5" component="h2">
              {video.title}
            </Typography>
            <Typography color={"grey"}>{FormatDate(video.date)}</Typography>
            <Typography color={"grey"}>
              {video.views +
                " vue" +
                (video.views > 1 ? "s" : "") +
                (video.likeCount && isAuth()
                  ? " - " + video.likeCount + " like" + (video.likeCount > 1 ? "s" : "")
                  : "")}
            </Typography>
            <Box gap={1} display="flex" my={1}>
              {tagsData &&
                tagsData
                  .filter((tag) => video.tagIds.includes(tag.id))
                  .map((tag) => <TagChip key={tag.id} tag={tag} />)}
            </Box>
          </Grid>
          {video.processing === 3 && (
            <Grid item md={5} sm={12}>
              <Select
                value={resolution}
                onChange={(e) => setResolution(e.target.value as number)}
                variant="standard"
                color="soft"
                sx={{ float: "right", top: 0, margin: 2 }}
              >
                {availableResolutions.map((resolution) => (
                  <MenuItem key={resolution} value={resolution}>
                    {`${resolution}p`}
                  </MenuItem>
                ))}
              </Select>
              {isAuth() ? (
                <>
                  <LikeButton
                    variant="contained"
                    color="primary"
                    sx={{ float: "right", top: 0, margin: 2 }}
                    onClick={() => handleLike()}
                    liked={liked}
                  >
                    <LikeIcon
                      style={{
                        width: "16px",
                        height: "16px",
                        fill: liked ? theme.palette.like.contrastText : theme.palette.soft.main,
                        transition: "all 0.15s ease",
                      }}
                    />
                    {"Like"}
                  </LikeButton>
                  <Button
                    onClick={handleDownload}
                    variant="outlined"
                    color="secondary"
                    sx={{
                      float: "right",
                      top: 0,
                      margin: 2,
                    }}
                  >
                    {"Télécharger"}
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Typography>
            <FormatParagraphs content={video.description} />
          </Typography>
        </Grid>
      </Grid>
    )
  )
}
